body {
  background: #ffffff;
  opacity: 0;
  overflow-x: hidden;
  &.is-reveal-open {
    overflow: hidden;
    .reveal {
      // transition: all 1.0s ease;
      // top: rem-calc(81);
      @include breakpoint(medium) {
        // top: rem-calc(71);
      }
    }
    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 998;
      background-color: rgba($black, 0.3);
    }
  }
}
main,
.main,
[role="main"] {
  // background: $white;
  padding-top: rem-calc(71);
  @include breakpoint(small only) {
    padding-top: rem-calc(0);
  }
}
figure {
  overflow: hidden;
}
img {
  vertical-align: inherit;
}
ul {
  margin-left: 0;
}
h1, h2, h3, h4, h5, h6 {
  // font-weight: bold;
  color: $primary-color;
  &.white {
    color: $white;
  }
}
h1 {
  text-transform: uppercase;
}
hr {
  border: none;
  background-color: $primary-color;
  height: 1px;
  width: 20vw;
  margin-bottom: rem-calc(30);
  &.white {
    background-color: $white;
  }
  @include breakpoint(medium) {
    width: 6vw;
  }
}
section {
  & > .grid-container {
    @include breakpoint(small only) {
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }
  }
}
.hidden {
  display: none !important;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.footer-hr {
  width: 100%;
  background-color: #979797;
}
.index {
  padding: rem-calc(100 0);
}
.small-text-center {
  @include breakpoint(small only) {
    text-align: center;
  }
}
.small-align-center {
  @include breakpoint(small only) {
    justify-content: center;
  }
}
.medium-align-right {
  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}
.breadcrumbs {
  margin-bottom: 0;
  background-color: $white;
  li {
    &:not(:last-child) {
      &:after {
        content: url(../img/breadcrumb-separator.svg);
        width: 7px;
        height: 12px;
        top: 2px;
      }
    }
    &.current {
      font-weight: bold;
    }
  }
}
p {
  // color: #626262;
  &.subhead {
    // font-family: $subheader-font-family;
    margin-bottom: rem-calc(30);
  }
  &.lead {
    font-family: $header-font-family;
    font-size: rem-calc(18);
    text-transform: uppercase;
    // font-weight: bold;
    // line-height: rem-calc(20);
    color: #3b3b3b;
    margin-bottom: rem-calc(30);
  }
  &.white {
    color: $white;
  }
  &:last-child {
    margin-bottom: rem-calc(32);
  }
}
.h100 {
  height: 100%;
}
.row.expanded>.columns,
.row.expanded>.column {
  padding-left: 0;
  padding-right: 0;
}
.rd {
  color: $primary-color;
}

a,
input[type="submit"],
.button {
  &.rounded {
    border-radius: 3rem !important;
  }
  &.gradient {
    background: $white;
  }
  &.white {
    background: $white;
    color: $pi-magenta;
    &:hover, &:focus {
      background: $pi-magenta;
      color: $white;
    }
  }
  &.underlined {
    border-bottom: 2px solid $pi-magenta;
    &:hover, &:focus {
      color: scale-color($pi-magenta, $lightness: -24%);
      padding-bottom: rem-calc(4);
    }
  }
  &.bold {
    font-weight: bold;
    min-width: rem-calc(225);
  }
}
a.home {
  display: flex;
}
.tabs {
  margin-left: auto;
  margin-right: auto;
  display: table;
  .tabs-title {
    margin-right: rem-calc(25);
    @include breakpoint(small only) {
      margin-right: rem-calc(-1);
    }
    &:last-child {
      margin-right: 0;
    }
    & > a {
      @include breakpoint(small only) {
        font-size: rem-calc(16);
        border-radius: 0 !important;
      }
    }
    .button {
      background-color: $white;
      border-color: $dark-gray;
      padding: rem-calc(16 55);
      @include breakpoint(small only) {
        padding: rem-calc(16 10);
      }
      @include breakpoint(medium only) {
        padding: rem-calc(16 25);
      }
    }
    &.is-active {
      .button {
        background-color: $pi-magenta;
        border-color: $pi-magenta;
      }
    }
  }
}
.cell.content {
  p {
    font-size: rem-calc(16);
    line-height: 1.6;
  }
}
.image100 {
  display: flex;
  img {
    width: 100%;
    height: 100%;
    max-width: none;
  }
}
picture {
  display: flex;
  flex-flow: column;
}
button>span>span {
  font-size: initial;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  z-index: 199999;
}
.loader {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.overlay {
  background-color: rgba(0, 0, 0, .6);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.shaded {
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-color: $black;
    opacity: 0.6;
    z-index: 0;
  }
}

.more-button {
  a {
    box-shadow: 20px -2px 35px 0 rgba($black, 0.11);
    transition: box-shadow 0.4s, width 0.4s ease-in-out;
    // width: 35%;
    img {
      transition: transform 0.4s ease;
    }
    &:hover,
    &:focus {
      box-shadow: 5px -1px 35px 0 rgba($black, 0.11);
      // width: 100%;
      img {
        transform: rotate(-45deg);
      }
    }
  }
}
.leafs {
  background-image: url("../img/leafs-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

$section-height-small: rem-calc(600);
$section-height: rem-calc(490);
#Home-RD-Natural {
  padding: rem-calc(50 20 60);
  background-color: $white;
  p {
    margin-bottom: rem-calc(32);
  }
  @include breakpoint(medium) {
    padding: rem-calc(105 0 160);
  }
  .button {
    text-transform: uppercase;
  }
}
#Home-RD-Brand,
#Home-RD-Ingredients {
  position: relative;
  min-height: $section-height;
  padding: rem-calc(25 0 35);
  margin-bottom: rem-calc(50);
  @include breakpoint(medium) {
    padding: rem-calc(0 0 65);
    margin-bottom: rem-calc(100);
  }
  & > .grid-container {
    min-height: $section-height;
    & > .grid-x {
      min-height: $section-height;
    }
  }
  h1 {
    color: $black;
    margin-bottom: rem-calc(26);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(35);
    }
  }
  .section-bg {
    position: absolute;
    height: $section-height;
    width: 100%;
    background-color: #faeef7;
    @include breakpoint(medium) {
      width: 33.33%;
      top: 0;
    }
  }
  .medium-6 {
    p {
      @include breakpoint(medium) {
        padding-right: 12%;
      }
    }
  }
  p {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
#Home-RD-Brand {
  .section-bg {
    right: 0;
    @include breakpoint(medium) {
      right: 66.66%;
    }
  }
}
#Home-RD-Ingredients {
  .section-bg {
    right: 0;
    @include breakpoint(small only) {
      height: $section-height-small;
    }
    @include breakpoint(medium) {
      width: 35%;
    }
  }
}
.ingredients-images {
  @include breakpoint(small only) {
    height: $section-height-small;
  }
}
.brand-images,
.ingredients-images {
  position: relative;
  min-height: $section-height;
}
.brand-texts,
.ingredients-texts {
  padding-top: rem-calc(35);
}
.brand-img {
  overflow: hidden;
  position: absolute;
  display: flex;
}
.safari {
  .brand-img {
    display: block;
  }
}
.brand-img-1 {
  top: 5%;
  @include breakpoint(medium) {
    left: 15%;
    top: -10%;
    box-shadow: 0 23px 45px 0 rgba($black, 0.19);
  }
}
.brand-img-2 {
  right: 0;
  bottom: rem-calc(-80);
  @include breakpoint(medium) {
    top: rem-calc(80);
    bottom: auto;
  }
}
.ingredients-img-1 {
  @include breakpoint(small only) {
    position: relative;
    top: 0;
  }
  // top: 65%;
  // transform: translateY(-65%);
  @include breakpoint(medium) {
    top: 75%;
    transform: translateY(-75%);
    left: 8%;
  }
}
.ingredients-img-2 {
  @include breakpoint(small only) {
    position: relative;
  }
  left: 10%;
  top: 5%;
  @include breakpoint(medium) {
    left: auto;
    right: 6%;
    top: -70px;
  }
}
.ingredients-img-3 {
  @include breakpoint(small only) {
    position: relative;
    margin-left: auto;
    margin-right: 5%;
    top: -5%;
  }
  text-align: right;
  @include breakpoint(medium) {
    right: 0;
    bottom: -50px;
    bottom: -70px;
  }
}

#Home-RD-Luminous {
  padding: rem-calc(50 0 60);
  background-color: #f9f7f3;
  @include breakpoint(medium) {
    padding: rem-calc(85 0 60);
  }
  .button {
    text-transform: uppercase;
  }
}
.luminous-carousel {
  margin-bottom: rem-calc(30);
  .owl-stage-outer {
    // padding: rem-calc(0 20);
    // margin-left: -25px;
    // margin-right: -25px;
    .owl-item {
      
    }
  }
}
.luminous-item {
  // min-width: 250px;
  // @include breakpoint(medium) {
    padding: rem-calc(25 25 0);
    position: relative;
    .product-labels {
      position: absolute;
      top: 35px;
      left: 25px;
      @include breakpoint(small only) {
        top: 40px;
      }
      .product_label {
        &.custom {
          float: none;
          clear: left;
          line-height: 16px;
          margin: 0;
          padding: 4px 8px;
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          background: $primary-color;
          color: $white;
          position: static;
        }
      }
    }
  // }
}
.luminous-item-wrapper {
  padding: rem-calc(0 0 40);
  text-align: center;
  background-color: $white;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 0 0 auto;
  .lead {
    margin-bottom: 0;
    transition: color 0.3s;
    font-size: rem-calc(12);
    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
  img {
    width: auto !important;
    margin-bottom: rem-calc(40);
  }
  &:hover,
  &:active {
    text-decoration: none;
    .lead {
      color: $primary-color;
    }
  }
}
#Home-Gifts {
  padding: rem-calc(45 0 60);
  background-color: white;
  position: relative;
  @include breakpoint(medium) {
    padding: rem-calc(80 0 155);
    background-image: url(../img/gifts-bg-lg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    hr {
      margin-left: 0;
    }
  }
  h1 {
    font-size: rem-calc(36);
    text-transform: none;
    @include breakpoint(medium) {
      font-size: rem-calc(48);
    }
  }
  .leafs {
    background-position: center top 100px;
  }
  .button {
    text-transform: uppercase;
    @include breakpoint(medium) {
      margin-top: rem-calc(25);
    }
  }
}
.gifts-wrapper {
  position: relative;
  &>.grid-container {
    @include breakpoint(small only) {
      // padding-left: 0;
      // padding-right: 0;
    }
    &>.grid-x {
      width: auto;
    }
  }
}
.gift-box {

    @include breakpoint(medium) {
      box-shadow: 0 17px 43px 0 rgba(206, 206, 206, 0.5);
    }

}
#Home-Roses {
  padding: rem-calc(210 0 200);
  // background-color: #e2d7d1;
  background-image: url("../img/image-roses.jpg");
  background-repeat: no-repeat;
  // background-position: right center;
  background-size: contain;
  max-height: rem-calc(630);
  h1 {
    color: #936b6e;
    line-height: 2;
    text-transform: none;
  }
}
#Home-RD-Valley {
  position: relative;
  background-image: url(../img/leafs-bg-lg.png);
  background-repeat: repeat-y;
  background-position: right;
  @include breakpoint(small only) {
    padding: rem-calc(50 0 60);
    // background-color: #f9f7f3;
    // background-image: url("../img/valley-top-bg.jpg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
  }
  @include breakpoint(medium) {
    padding: rem-calc(150 0 110);
    // border-bottom: 1px solid #c0bcbc;
  }
  // h1 {
  //   text-transform: none;
  //   @include breakpoint(medium) {
  //     font-size: rem-calc(48);
  //     margin-bottom: rem-calc(42);
  //   }
  // }
  .leafs {
    background-position: center top -400px;
  }
  .button {
    margin-top: rem-calc(25);
    text-transform: uppercase;
  }
  p {
    line-height: 1.8;
    // @include breakpoint(medium) {
    //   padding-right: 15%;
    // }
  }
  .valley-image-2 {
    img {
      margin-top: rem-calc(-40);
      margin-bottom: rem-calc(50);
    }
  }
  .valley-image-3 {
    img {
      margin-left: rem-calc(20);
    }
  }
}
.valley-wrapper {
  position: relative;
}
.valley-images {
  overflow: hidden;
  margin-bottom: rem-calc(25);
  .img1 {}
  .img2 {
    margin-top: rem-calc(-50);
  }
}
#Home-TAE {
  padding: rem-calc(20 0 25);
  @include breakpoint(medium) {
    padding: rem-calc(100 0 120);
    border-bottom: 1px solid #c0bcbc;
  }
  &>div {
    padding: 0;
  }
}
.home-testimonials-wrapper,
.home-articles-wrapper,
.home-events-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.tae-carousel {
  flex: 1 1 100%;
  padding-bottom: 40px;
}
.tae-item {
  padding: rem-calc(0 15 0);
  @include breakpoint(medium) {
    // width: 100%;
    flex: 1 0 100%;
    display: inline-flex !important;
  }
}
.tae-item-wrapper {
  text-align: center;
  padding: rem-calc(50 25 20);
  border: 1px solid $primary-color;
  min-height: rem-calc(340);
  display: flex;
  flex-flow: column;
  flex: 1 0 100%;
  justify-items: stretch;
  @include breakpoint(medium) {
    min-width: rem-calc(350);
  }
}
.tae-title {
  background-color: $primary-color;
  color: $white;
  font-family: $header-font-family;
  // font-size: rem-calc(14);
  margin-bottom: rem-calc(-34);
  padding: rem-calc(5 22);
  align-self: center;
}
.tae-content {
  margin-bottom: rem-calc(21);
  width: 100%;
  hr {
    background-color: $dark-gray;
  }
  p {
    font-family: $header-font-family;
    // font-size: rem-calc(14);
  }
  .testimonial-name {
    font-size: rem-calc(14);
    font-weight: bold;
  }
  .testimonial-position {
    font-size: rem-calc(14);
  }
  .event-date {
    font-size: rem-calc(14);
  }
}
.tae-cta {
  text-transform: uppercase;
  position: absolute;
  top: rem-calc(270);
  left: 50%;
  transform: translateX(-50%);
  &>a {
    background-color: $white !important;
  }
}

#Home-TAE-new {
  background-color: #f9f7f3;
  padding: rem-calc(45 0 55);
  @include breakpoint(medium) {
    padding: rem-calc(116 0 116);
  }
  a {
    position: relative;
    display: block;
  }
  h3 {
    text-transform: uppercase;
    color: $white;
    position: absolute;
    margin-bottom: 0;
    z-index: 2;
    @include breakpoint(small only) {
      font-size: rem-calc(32);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.tae-testimonials {
  margin-bottom: rem-calc(30);
  @include breakpoint(medium) {
    padding-top: rem-calc(130);
    margin-bottom: 0;
  }
  h3 {
    left: rem-calc(30);
    @include breakpoint(small only) {
      // top: rem-calc(30);
    }
    @include breakpoint(medium) {
      bottom: rem-calc(30);
    }
  }
}
.tae-articles {
  h3 {
    top: rem-calc(30);
    @include breakpoint(medium) {
      right: rem-calc(30);
    }
  }
}
.tae-testimonials,
.tae-articles {
  a {
    overflow: hidden;
    .img-wrapper {
      display: flex;
      position: relative;
      transition: transform 0.4s;
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.2);
        transition: background-color 0.4s, transform 0.4s;
        z-index: 1;
      }
    }
    &:hover {
      .img-wrapper {
        transform: scale(1.2);
        &:before {
          background-color: rgba($black, 0.4);
        }
      }
    }
  }
}
.safari {
  .tae-testimonials,
  .tae-articles {
    a {
      .img-wrapper {
        display: block;
      }
    }
  }
}

#Home-Instagram {
  padding: rem-calc(30 0 25);
  @include breakpoint(medium) {
    padding: rem-calc(105 0 80);
  }
  h1 {
    text-transform: none;
  }
}
.instagram-carousel {}
.instagram-item {}
.instagram-item-wrapper {
  padding: rem-calc(20);
  // display: flex;
  // justify-content: center;
}
#Home-NewsletterSubscribe {
  padding: rem-calc(30 0 85);
  h3 {
    color: $black;
    margin-bottom: rem-calc(30);
  }
  h4 {
    color: #3b3b3b;
  }
  .social-links {
    color: #3b3b3b;
    font-size: 1.5rem;
    font-family: $header-font-family;
  }
  li {
    display: inline-block;
    a {
      color: #3b3b3b;
      font-size: 2rem;
      font-family: $header-font-family;
      transition: color 0.3s;
      &:hover {
        color: $primary-color;
      }
    }
    &:after {
      content: "/";
      display: inline-block;
      margin: rem-calc(0 32);
      font-size: rem-calc(20);
      vertical-align: text-bottom;
      padding-bottom: 3px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  .lead {
    font-family: $body-font-family;
  }
  .input-group-field {
    border-right-color: $white;
  }
  form {
    margin-bottom: rem-calc(80);
    [type="submit"] {
      padding-left: rem-calc(16);
      padding-right: rem-calc(16);
    }
  }
}
.gift-box {
  margin-bottom: rem-calc(25);
  overflow: hidden;
}

/* About us Page */

/* Story Page */
#Story {
  background-image: url(../img/leafs-bg-lg.png);
  background-repeat: repeat-y;
  // background-size: 100%;
  background-position: right 0px;
  padding-bottom: 120px;
  @include breakpoint(medium) {
    padding-bottom: 200px !important;
  }
  h2 {
    font-size: 24px;
    position: relative;
    display: inline-block;
  }
  .story-text-p {
    position: relative;
  }
  p {
    font-size: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.story-paragraph {
  // height: 100vh;
  margin-bottom: 120px !important;
  position: relative;
  @include breakpoint(medium) {
    margin-bottom: 200px !important;
    // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8+HCbMQAIEAK9Ki6AQAAAAABJRU5ErkJggg==);
    // background-repeat: no-repeat;
    // background-size: 100% 50%;
    // background-position-y: 0px;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  &:last-child {
    margin-bottom: 0 !important;
  }
  .grid-container {
    @include breakpoint(small only) {
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }
  }
  &>.grid-x {
    position: relative;
    height: 100%;
  }
  .cell-img,
  .story-text {
    position: relative;
  }
  .small-order-1 {
    @include breakpoint(small only) {
      margin-bottom: rem-calc(30);
    }
  }
}
.roses-bg {
  width: 100%;
  position: relative;
  text-align: center;
  padding-top: 20px;
  margin-bottom: -80px;
  z-index: 0;
}
.trigger {
  height: 1px;
  width: 100%;
}
.alpha-content0 {
  & > .grid-x {
    & > .cell {
      & > .grid-container {
        @include breakpoint(small only) {
          padding-left: rem-calc(20);
          padding-right: rem-calc(20);
        }
      }
    }
  }
}
.blog-cat-view {
  .alpha-content0 {
    @extend #Story;
    background-image: url(../img/leafs-blog-bg-lg.jpg);
  }
}
.blog-post-view {
  .alpha-content0 {
    @extend #Story;
  }
}

.catalog-category-view {}
.catalogCategoryProductList {
  background-color: #f9f7f3;
  .category-cms-block {
    background-color: $white;
    // margin-bottom: 0;
    padding-top: rem-calc(90);
    padding-bottom: rem-calc(40);
    @include breakpoint(medium) {
      padding-top: rem-calc(100);
      padding-bottom: rem-calc(80);
    }
    h1 {
      font-size: rem-calc(36);
      text-transform: none;
      margin-bottom: rem-calc(42);
      @include breakpoint(medium) {
        font-size: rem-calc(48);
      }
    }
  }
}
.category-products {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(50);
  @include breakpoint(medium) {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(70);
  }
  .products-grid {
    .item {
      .product-list-grid-box {
        background-color: #fff;
        border: none;
        .product-action * {
          display: none;
        }
        .product-info {
          .ratings {
            display: none;
          }
        }
        .product-name {
          margin-bottom: rem-calc(12);
        }
      }
    }
  }
  .product-image {
    img {
      padding-top: 0;
    }
  }
}
#RosesInfo {
  min-height: 100vh;
  background-image: url(../img/roses-info-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include breakpoint(medium) {
    min-height: rem-calc(600);
  }
  @include breakpoint(large) {
    min-height: rem-calc(720);
  }
  h1 {
    font-size: rem-calc(28);
    color: $white;
    text-transform: none;
    margin-bottom: rem-calc(32);
    @include breakpoint(medium) {
      font-size: rem-calc(36);
    }
  }
  .lead {
    color: $white;
    margin-bottom: 0;
  }
}
#Ingredients {
  padding: rem-calc(65 0 100);
  @include breakpoint(medium) {
    padding: rem-calc(75 0 200);
  }
  h1 {
    font-size: rem-calc(32);
    text-transform: none;
    margin-bottom: rem-calc(5);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(75);
      font-size: rem-calc(48);
    }
  }
  h2 {
    font-size: rem-calc(21);
    margin-bottom: rem-calc(28);
    @include breakpoint(medium) {
      font-size: rem-calc(24);
      margin-bottom: rem-calc(32);
    }
  }
  ul {
    margin-left: 20px;
    li {
      list-style: inherit;
    }
  }
  .grid-x {
    margin-bottom: rem-calc(30);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(60);
    }
    &.overlap {
      @include breakpoint(medium) {
        margin-top: rem-calc(-95);
        margin-bottom: rem-calc(-25);
      }
    }
  }
  .cell-img {
    background-color: #f4f2ed;
    &.shadow {
      position: relative;
      @include breakpoint(medium) {
        box-shadow: 0 18px 17px 0 rgba($black, 0.1);
      }
    }
    &.cell-img-1 {}
    &.cell-img-2 {
      padding: rem-calc(35 25 35);
      @include breakpoint(medium) {
        // padding: rem-calc(95 75 95 80);
      }
    }
    &.cell-img-3 {
      padding: rem-calc(35 25 35);
      @include breakpoint(medium) {
        padding: rem-calc(125 25 35);
      }
    }
    &.cell-img-4 {
      padding: rem-calc(35 25 35);
      @include breakpoint(medium) {
        // padding: rem-calc(85 95 120);
      }
    }
    @include breakpoint(small only) {
      margin-bottom: rem-calc(20);
    }
  }
}

/* Product page */
.product-page {
  padding-top: rem-calc(50);
  @include breakpoint(medium) {
    padding-top: rem-calc(85);
  }
  .product-name {
    h1 {
      font-size: rem-calc(32);
      text-transform: none;
      @include breakpoint(medium) {
        font-size: rem-calc(48);
      }
    }
  }
  .product-details-box {
    margin-top: rem-calc(45);
  }
  .accordion-item {
    .accordion-title {
      border: none;
      border-top: 1px solid #979797;
      font-size: rem-calc(16);
      text-transform: uppercase;
      color: $black;
      &:before {
        color: $primary-color;
        font-size: rem-calc(36);
        line-height: 14px;
      }
      &:hover {
        text-decoration: none;
        background-color: scale-color($light_gray, $lightness:50%);
      }
      &:focus,
      &:active {
        background-color: none;
        text-decoration: none;
      }
    }
    &:last-child {
      &:not(.is-active) {
        .accordion-title {
          border-bottom: 1px solid #979797;
        }
      }
      &.is-active {
        .accordion-content {
          border-bottom: 1px solid #979797;
        }
      }
    }
    .accordion-content {
      border: none;
    }
  }
}

#SusVideo {
  width: 640px;
  height: 360px;
}

.cookie-disclaimer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  .cookie-disclaimer-content {
    padding: 5px 30px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    color: #fff;
    p {
      margin: 0;
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

#Locator {
  padding-bottom: rem-calc(80);
  @include breakpoint(medium) {
    padding-bottom: rem-calc(120);
  }
  p {
    margin-bottom: rem-calc(6);
  }
  .cell {
    @include breakpoint(small only) {
      margin-top: rem-calc(50);
    }
  }
}
.locator-title {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(40);
  @include breakpoint(medium) {
    margin-top: rem-calc(60);
    // margin-bottom: rem-calc(80);
  }
  h1 {
    margin-bottom: rem-calc(30);
  }
}
.gmap {
  height: rem-calc(400);
  margin-top: rem-calc(20);
  @include breakpoint(small only) {
    height: rem-calc(280);
  }
}

.category-products {
  .products-grid {
    .item {
      .product-list-grid-box {
        .image-container {
          position: relative;
        }
      }
    }
  }
  .product-labels {
    position: absolute;
    top: 10px;
    left: 0px;
    .product_label {
      &.custom {
        float: none;
        clear: left;
        line-height: 16px;
        margin: 0;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        background: $primary-color;
        color: $white;
        position: static;
      }
    }
  }
}
.product-page {
  .product-short-info-box {
    position: relative;
    .product-labels {
      position: absolute;
      top: -25px;
      left: 0px;
      @include breakpoint(small only) {
        top: -10px;
      }
      .product_label {
        &.custom {
          float: none;
          clear: left;
          line-height: 16px;
          margin: 0;
          padding: 4px 8px;
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          background: $primary-color;
          color: $white;
          position: static;
        }
      }
    }
  }
  .product-shop-container {
    @include breakpoint(small only) {
      padding-top: rem-calc(30);
    }
  }
  .product-media-box {
    @include breakpoint(small only) {
      margin-top: 0;
    }
  }
}




/////////////////////
@media (min-width: 992px) {
  .content-wrapper {
      padding-top: 50px;
      padding-bottom: 120px;
  }
}
/////////////////////
// success page
.checkout-onepage-success {
  .alpha-content0 {
    @extend #Story;
    .content-wrapper {
      h1 {
        text-transform: capitalize;
        margin-bottom: rem-calc(20);
      }
      .order_no {
        display: inline-block;
        background-color: $white;
        border: 1px solid $primary-color;
        padding: rem-calc(25 35 30);
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(40);
        font-size: rem-calc(24);
        text-transform: uppercase;
        color: #a69d9d;
        &>span {
          font-weight: bold;
          color: $black;
        }
      }
      .order_corfirm {
        font-size: rem-calc(22);
        color: #6e6e6e;
        @include breakpoint(medium) {
          margin-bottom: rem-calc(60);
        }
      }
    }
  }
  .buttons-set {
    .button > span {
      color: $primary-color;
    }
  }
}