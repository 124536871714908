$footer-gray: #6b6b6b;
footer {
  background-color: #f9f7f3;
  color: $footer-gray;
  font-size: rem-calc(14);
  padding: rem-calc(100 0 80);
  .menu {
    margin-left: 0;
    a {
      // font-family: $subheader-font-family;
      color: $footer-gray;
      font-size: rem-calc(14);
      padding: rem-calc(0 9);
      line-height: 1.6;
      transition: color 0.3s;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .logo-footer {
    // width: 140px;
    // height: auto;
  }
  p {
    color: $footer-gray;
    // font-family: $subheader-font-family;
    margin-bottom: 0;
    &.lead {
      font-size: rem-calc(20);
      color: $footer-gray;
      margin-bottom: rem-calc(26);
    }
    &.logo {
      padding: rem-calc(15 0 0 40);
      @include breakpoint(small only) {
        padding-left: 0;
      }
    }
    a {
      color: $footer-gray;
      text-decoration: underline;
      transition: color 0.3s;
    }
  }
  .fa {
    margin-right: rem-calc(10);
  }
  @include breakpoint(small only) {
    padding: rem-calc(60 0 30);
    text-align: center;
    div.cell {
      margin-bottom: rem-calc(25);
    }
    ul {
      text-align: center !important;
    }
  }
}