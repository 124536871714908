$hero-height-large: rem-calc(600);
$hero-height-xlarge: rem-calc(740);
$hero-height-mobile: calc(100vh - 81px);
#HomeHero {
  // overflow: hidden;
  position: relative;
  @include breakpoint(small only) {
    background-color: #f3f3f5;
    overflow: hidden;
  }
  @include breakpoint(xlarge) {
    // padding-bottom: rem-calc(300);
  }
  .hero-container {
    position: relative;
    .carousel-item {
      position: relative;
      justify-content: center;
      align-items: center;
      // padding-top: rem-calc(65);
      min-height: $hero-height-large;
      overflow: hidden;
      @include breakpoint(small only) {
        min-height: $hero-height-mobile;
        padding-top: rem-calc(15);
      }
      @include breakpoint(xlarge) {
        min-height: $hero-height-xlarge;
      }
      &>.cell {
        position: relative;
        @include breakpoint(small only) {
          align-self: flex-start;
          padding-top: 0;
          bottom: 10vh;
          position: absolute;
        }
      }
      h1 {
        font-size: rem-calc(55);
        margin-bottom: rem-calc(10);
        line-height: 1;
        letter-spacing: 3.4px;
        color: $primary-color;
        @include breakpoint(small only) {
          font-size: rem-calc(36);
          margin-bottom: rem-calc(5);
          letter-spacing: 0px;
        }
      }
      p.subhead {
        font-size: rem-calc(20);
        color: #5d5d5d;
      }
      a.button {
        margin-bottom: 0;
        text-transform: uppercase;
        &.show-for-small-only {
          position: absolute;
          white-space: nowrap;
          left: 50%;
          bottom: 45%;
          transform: translateX(-50%);
          opacity: 0;
        }
      }
      .carousel-item-bg {
        // transition: all 6.6s ease-in-out;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70%;
        @include breakpoint(medium) {
          height: 100%;
        }
        @include breakpoint(small only) {
          &:after {
            content: "";
            height: 11vh;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -1px;
            background-image: linear-gradient(to top, rgba(243, 243, 245, 1) 1%, rgba(243, 243, 245, 0));//#f3f3f5
          }
        }
      }
      .carousel-item-text {
        min-height: $hero-height-large;
        align-items: center;
        display: flex;
        top: 0;
        @include breakpoint(small only) {
          min-height: $hero-height-mobile;
          align-items: flex-end;
          pointer-events: none;
        }
        @include breakpoint(xlarge) {
          min-height: $hero-height-xlarge;
        }
      }
    }
    .active {
      .carousel-item-bg {
        // transform: scale(1.1);
      }
    }
    .cloned {
      .carousel-item-bg {
        // transform: scale(1.1);
      }
    }
    .hero-wrapper {
      flex: 1 1 auto;
      position: relative;
      z-index: 2;
      @include breakpoint(small only) {
        // text-align: center;
        padding-bottom: 5vh;
      }
      svg,
      .svg {
        width: 18vw;
        height: auto;
        @include breakpoint(small only) {
          width: 50vw;
        }
        @include breakpoint(medium only) {
          width: 30vw;
        }
      }
      &>.grid-x {
        position: relative;
        z-index: 2;
      }
    }
  }
  .carousel-nav {
    .myslick-prev,
    .myslick-next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      img {
        width: 22px;
        height: 44px;
      }
      @include breakpoint(small only) {
        // right: 15px;
      }
    }
  }
}
.slick-dots,
.carousel-dots {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  .myslick-dots {
    margin-bottom: 0;
    li {
      display: inline-block;
      margin-right: rem-calc(10);
      @include breakpoint(medium) {
        margin-right: rem-calc(14);
      }
      &:last-child {
        margin-right: 0;
      }
      button {
        cursor: pointer;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        border: 1px solid $primary-color;
        font-size: 0;
        line-height: 0;
        @include breakpoint(medium) {
          width: 14px;
          height: 14px;
        }
      }
      &.slick-active {
        button {
          background-color: $primary-color;
        }
      }
    }
  }
}
.slick-dots {
  bottom: 10px;
}
.slick-dots li button:before {
  // font-size: 25px;
}
.hero-elm {
  width: 57%;
  height: auto;
}

$hero2-height-mobile: 95vh;
$hero2-height-large: rem-calc(480);
$hero2-height-xlarge: rem-calc(520);
$project-hero-height-mobile: 95vh;
$project-hero-height-large: rem-calc(650);
$project-hero-height-xlarge: rem-calc(700);
#HeroInner {
  @extend #HomeHero;
  .hero-container {
    .carousel-item {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: $hero2-height-large;
      overflow: hidden;
      @include breakpoint(small only) {
        min-height: $hero2-height-mobile;
      }
      @include breakpoint(xlarge) {
        min-height: $hero2-height-xlarge;
      }
      h1 {
        font-weight: 300;
        font-size: rem-calc(72);
        line-height: 1;
      }
      p.subhead {
        font-size: rem-calc(16);
        opacity: 0.7;
      }
    }
  }
  &.project {
    height: rem-calc(700);
    @include breakpoint(small only) {
      height: 100vh;
    }
    .hero-container {
      .carousel-item {
        min-height: $project-hero-height-large;
        @include breakpoint(small only) {
          min-height: $project-hero-height-mobile;
        }
        @include breakpoint(xlarge) {
          min-height: $project-hero-height-xlarge;
        }
        h1 {
          font-weight: bold;
          font-size: rem-calc(54);
          @include breakpoint(small only) {
            font-size: rem-calc(36);
          }
        }
        .subhead {
          font-size: rem-calc(24);
          font-style: italic;
          color: $white;
          opacity: 1;
        }
        &.shaded {
          &:after {
            background-image: linear-gradient(47deg, $black, rgba($black, 0));
            background-color: transparent;
          }
        }
      }
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
.ActivitiesPage,
.ActivityPage,
.PortfolioPage {
  #HeroInner {
    .hero-container {
      &:before,
      &:after {
        content: "";
        display: none;
      }
    }
  }
}
.AboutusPage {
  #HeroInner {
    .hero-container {
      &:before {
        content: "";
        display: none;
      }
    }
  }
}

$about-height-mobile: 80vh;
$about-height-large: rem-calc(485);
$about-height-xlarge: rem-calc(520);
#Aboutus {
  min-height: $about-height-mobile;
  background-color: #eff7ff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPU4vf6DwACcgGEVcOCJQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: right center;
  @include breakpoint(small only) {
    background-size: 10% 100%;
  }
  @include breakpoint(medium) {
    min-height: $about-height-large;
  }
  @include breakpoint(large) {
    min-height: $about-height-xlarge;
  }
  .text-cell {
  }
  .image-cell {
    background-color: #2a0f4a;
  }
}