
.site-search-form {
  position: fixed;
  top: -115px;
  z-index: 10000;
  width: 100%;
  padding: rem-calc(35 20 20);
  background-color: $white;
  transition: all 0.4s ease-out;
  .input-group {
    margin-top: 20px;
    margin-bottom: 0;
  }
  form {
    // border-bottom: 1px solid #979797;
  }
  input[type="text"] {
    // margin-bottom: 0;
    // border-radius: 0;
    // border-color: transparent;
    // font-size: rem-calc(24);
    // width: calc(100% - 70px);
    // display: inline-block;
    border-right-color: $white;
    padding: rem-calc(8);
  }
  button {
    padding: rem-calc(13 15);
    // border-radius: 50%;
    svg,
    .svg {
      width: 22px;
      height: 22px;
    }
  }
  &.expanded {
    top: 0;
    box-shadow: 0 0 15px rgba($black, 0.1);
  }
}