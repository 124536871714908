[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  border-radius: $global-radius;
  border: solid 1px $medium-gray;
  background-color: $white;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: auto;//2.4375rem;
  margin: 0 0 1rem;
  padding: rem-calc(12 12 12 12);
  box-shadow: none;
  font-family: inherit;
  font-size: rem-calc(16);
  font-weight: normal;
  line-height: 1.5;
  // color: $white;
  appearance: none;
  transition: all 0.4s ease;
  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-color: #f3f3f3;
    border-color: $light-gray;
    box-shadow: none;//0 0 5px #cacaca;
  }
  &[required="required"]+label {
    &:after {
      content: '*';
      display: block;
    }
  }
}
textarea {
  // background-color: #153c75;
  // border: solid 1px transparent;//$medium-gray;
  &:focus {
    // border-color: $white;
  }
}
select {
  padding-right: rem-calc(24);
//   transition: box-shadow 0.4s, border-color 0.3s ease,  background-color 0.3s ease,  color 0.3s ease;
//   background-position: right -26px center;
//   border-color: transparent;
//   box-shadow: 0 8px 11px 0 rgba($black, 0.08);
//   &:hover {
//     background-color: $primary-color;
//     background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='18' viewBox='0 0 32 18'><polygon points='0,0 32,0 16,18' style='fill: #fff'></polygon></svg>");
//     color: $white;
//     border-color: $primary-color;
//   }
//   option {
//     background-color: $white;
//     color: $dark-gray;
//   }
}
label {
  // color: $white;
  // font-size: rem-calc(14);
  // margin-bottom: rem-calc(6);
}
button,
.button {
  font-weight: normal;
}

/*.form-control {
  color: $white;
  background-color: #262626;
  border-radius: 0;
  border: 1px solid #7e7e7e;
  outline: none;
  height: calc(2.35rem + 8px);
  transition: all 0.3s ease;
  &:focus,
  &:hover {
    color: $white;
    background-color: #555555;
    border-color: #444444;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba($white, 0.5);
  }
}*/

/*select {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><path fill="#D2D2D2" fill-rule="nonzero" d="M4 0l4 6H0l4-6zm0 16l-4-6h8l-4 6z"/></svg>');
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
  &.form-control {
    background-color: #555555;
    //border-color: #555555;
    color: #c9c9c9;
    padding: 0.375rem 1.5rem 0.375rem 0.75rem;
  }
}*/
/*.select-or {
  position: relative;
  margin-bottom: 2rem !important;
  &:after {
    display: flex;
    content: "OR";
    color: #c7c6c6;
    font-size: 1rem;
    padding: 3px 15px;
    border-radius: 6px;
    border: 1px solid #979797;
    background-color: #444444;
    position: absolute;
    z-index: 1;
    top: calc(80px + 1rem);
    left: 50%;
    transform: translateX(-50%);
  }
  &:before {
    display: flex;
    content: "";
    width: 1px;
    height: 5rem;
    background-color: #979797;
    position: absolute;
    z-index: 0;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.form-check {
  padding: 0;
}
.form-check-input {
  margin: 0;
  opacity: 0;
  width: 0;
  height: 0;
}*/
[type="checkbox"],
[type="radio"] {
  opacity: 0;
  position: absolute;
  &+label {
    line-height: 2;
    // display: inline-flex;
    position: relative;
    margin: 0;// 20px 8px 0;
    align-items: center;
    display: inline-flex;
    &:before {
      content: "";
      width: 22px;
      height: 22px;
      border: 1px solid #979797;
      border-radius: 50%;
      display: inline-flex;
      margin-right: 8px;
      vertical-align: text-bottom;
      flex: 0 0 auto;
    }
  }
  &:checked {
    &+label {
      &:after {
        content: "";
        display: flex;
        width: 14px;
        height: 14px;
        background-color: $primary-color;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: 4px;
        // transform: translate(-50%, 0);
      }
    }
    &+label[for*="paypal"] {
      &:after {
        top: 10px;
      }
    }
  }
}
[type="checkbox"] {
  &+label {
    line-height: 2;
    &:before {
      border-radius: 3px;
    }
  }
  &:checked {
    &+label {
      &:after {
        border-radius: 2px;
      }
    }
  }
}
.form-alt {
  font-size: rem-calc(12);
}



/*[type='checkbox'] {
  opacity: 0;
  position: absolute;
}
[type='checkbox']+label {
  margin-left: 0;
  display: flex;
  position: relative;
  // line-height: 1.4;
  &:before {
    margin-right: 15px;
    display: flex;
    content: '';
    width: 25px;
    height: 25px;
    // background-color: #153c75;
    border: solid 1px $medium-gray;
    border-radius: 3px;
    flex: 0 0 auto;
  }
}
[type='checkbox']:checked+label {
  &:after {
    content: '';
    display: flex;
    background-color: $medium-gray;
    // background-image: linear-gradient(to bottom, $medium-gray, #153c75);
    width: 17px;
    height: 17px;
    position: absolute;
    top: 4px;
    left: 4px;
  }
}*/

.input-group-field {
  margin-bottom: 0;
}
.form-group {
  margin-bottom: 0;
}

div.input-text {
  position: relative;
  @include breakpoint(medium up) {
    margin-bottom: rem-calc(38);
  }
  &>input {
    height: auto;
  }
  span.error {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    pointer-events: none;
    color: gold;
    font-size: rem-calc(18);
  }
  &.textarea {
    span.error {
      top: rem-calc(20);
    }
  }
}
span.label {
  @extend label;
  padding-left: 0;
  background: transparent;
}

/*.contact-form {
  .input-text {
    span {
      &.error {
        position: absolute;
        color: red;
      }
    }
  }
  .Actions {
    // text-align: center;
    a,
    input[type='submit'] {
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: normal;
      // color: $white;
      // font-size: rem-calc(18);
      // padding: rem-calc(12 50);
    }
  }
  #uff {
    padding-top: 10vh;
  }
}*/

/*.Actions {
  input[name="action"],
  input[name="action_dologin"] {
    @extend .button;
    width: 100%;
    font-size: rem-calc(16);
  }
  @include breakpoint(small only) {
    width: 100%;
    margin-top: rem-calc(35);
  }
}*/

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba($black, 0.5);
  opacity: 1; /* Firefox */
}
input::-webkit-input-placeholder { /* Chrome, Opera, Safari */
  color: rgba($black, 0.5);
}
input::-moz-placeholder { /* Firefox 19+ */
  color: rgba($black, 0.5);
}
input:-moz-placeholder { /* Firefox 18- */
  color: rgba($black, 0.5);
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba($black, 0.5);
}
input::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba($black, 0.5);
}


/*#MemberLoginForm_LoginForm,
#MemberLoginForm_LostPasswordForm {
  [type='text'],
  [type='email'],
  [type='password'] {
    padding: 0.5rem;
    color: $black;
    border-color: $medium-gray;
  }
}
#MemberLoginForm_LostPasswordForm {
  .Actions {
    input {
      @extend .button;
    }
  }
}*/