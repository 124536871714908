header {
  // position: fixed;
  z-index: 100;
  width: 100%;

  // Desktop navigation
  .title-bar {
    display: block;
    padding: 0;//rem-calc(5 0);
    font-size: rem-calc(16);
    height: rem-calc(71);
    border-bottom: 1px solid #d6d6d6;
    a:not(.checkout-button) {
      color: #6c6464;
      font-size: rem-calc(16);
      line-height: 18px;
      padding: rem-calc(20 12);
      letter-spacing: -0.7px;
      transition: all 0.25s;
      vertical-align: middle;
      @include breakpoint(medium only) {
        padding: rem-calc(15 5);
      }
      &:hover,
      &:focus {
        color: $primary-color;
      }
      &.home {
        padding: 0;
        .letter {
          transition: fill 0.3s linear;
        }
      }
    }
    .widget-content {
      li {
        a {
          padding: rem-calc(10 10);
        }
      }
    }
    .title-bar-left,
    .title-bar-right {
      flex: 1 1 auto;
    }
    .title-bar-right {
      .menu {
        &:not(.is-dropdown-submenu) {
          display: inline-flex;
        }
        li {
          &.current {
            a {
              // text-decoration: underline;
              color: $primary-color;
            }
          }
        }
      }
    }
    .title-bar-right-content {
      // overflow: hidden;
    }
  }

  // Mobile navigation
  .top-bar {
    // border-bottom: 1px solid $light-gray;
    background-color: $white;
    padding: rem-calc(15 6 8 20);
    outline: none;
    // z-index: 9999;
    .top-bar-left,
    .top-bar-center,
    .top-bar-right {
      flex: 1 1 33.3333%;
      // max-width: 50%;
    }
    .top-bar-right {
      text-align: right;
      align-self: normal;
      ul.menu {
        justify-content: flex-end;
        a {
          padding: rem-calc(10 8);
        }
      }
    }
    svg,
    .svg {
      width: auto;
      height: 25px;
    }
    ul {
      background-color: $white;
    }
  }
}
.sticky {
  // z-index: 121;
  // transition: background-color 0.3s linear;
  &.is-stuck {
    background-color: rgba($white, 1);
    // transition: background-color 0.3s linear;
    .title-bar {
      a {
        // color: #6c6464;
        // transition: color 0.3s linear;
        &:hover,
        &:focus {
          // color: $primary-color;
        }
        // &.home {
        //   .letter {
        //     transition: fill 0.5s linear 0.5s;
        //     fill: $white;
        //   }
        // }
      }
    }
  }
}
.HomePage {
  header {
    .title-bar {
      // border-bottom-color: transparent;
      transition: border-color 0.3s linear;
      a {
        // color: $white;
        &:hover,
        &:focus {
          // color: $primary-color;
        }

      }
    }
    .sticky {
      // transition: background-color 0.3s linear;
      &.is-stuck {
        // transition: background-color 0.3s linear;
        .title-bar {
          border-color: #d6d6d6;
          a {
            &.home {
              .letter {
                transition: fill 0.3s linear;
                fill: #7a7a7a;
              }
            }
          }
        }
      }
    }
  }
}
.main-menu {
  border-bottom: 1px solid #979797;
  a {
    color: $black;
    padding-left: 0;
    &:hover {
      color: $primary-color;
    }
  }
}
.language-menu {
  justify-content: flex-start !important;
  margin-top: rem-calc(20);
  a {
    color: #a0a0a0;
    padding-left: 0;
    &:hover {
      color: $black;
    }
  }
}

.dropdown.menu {
  a {
    padding: rem-calc(20 10);
    @include breakpoint(medium only) {
      padding: rem-calc(30 5);
    }
  }
  & > li.is-dropdown-submenu-parent {
    a {
      &:after {
        border-top-color: $white;
      }
    }
  }
  &.language-switcher {
    padding: rem-calc(9 0 9 10);
    & > li > a {
      padding: rem-calc(10 22);
      border: 1px solid #c2c2c2;
    }
    .is-dropdown-submenu {
      min-width: 100%;
      text-align: center;
      border-top: none;
      .is-dropdown-submenu-item {
        width: auto;
      }
    }
  }
}

.reveal {
  display: block;
  width: 100vw;
  height: calc(100vh - 81px);
  height: 100vh;
  position: fixed;
  padding: rem-calc(15 50 65);
  border: none;
  z-index: 999;
  font-size: rem-calc(18);
  right: -100vw;
  left: 100vw;
  top: 81px;
  top: 0;
  transition: all 0.6s ease;
  @include breakpoint(medium) {
    width: auto;
    min-width: 300px;
    max-width: 500px;
    height: calc(100vh - 71px);
    height: 100vh;
    top: 71px;
    top: 0;
    right: -501px;
    left: auto;
    box-shadow: -15px 15px 25px rgba($black, 0.1);
  }
  .vertical {
    margin-top: rem-calc(32);
    li {
      margin-bottom: rem-calc(12);
    }
  }
  .horizontal {
    a {
      font-size: rem-calc(14);
    }
  }
  a {
    // color: $white;
  }
  .float-left {
    span {
      width: 25px;
      display: inline-block;
    }
  }
  .menu {
    justify-content: center;
  }
}
body.is-reveal-open {
  .reveal {
    right: 0;
    // left: auto;
    @include breakpoint(small only) {
      left: 0;
    }
  }
}




.nav-toggle {
  background: none;
  z-index: 1011;
  pointer-events: all;
  display: inline-block;
}
.nav-toggle-bar {
  display: block;
  height: 2px;
  width: 30px;
  background: #929292;
  opacity: 1;
  transform: translate(0, 0);
  transition: transform .3s ease, opacity .3s ease-out;
}

.nav-toggle-bar:first-child {
  transform: translate(0, -8px)
}

.nav-toggle-bar:last-child {
  transform: translate(0, 8px)
}

// .is-reveal-open .nav-toggle-bar {
//   opacity: 0;
//   transform: translate(0, 0)
// }

// .is-reveal-open .nav-toggle-bar:first-child {
//   opacity: 1;
//   transform: translate(0, 2px) rotate(45deg) scale(1.2, 1)
// }

// .is-reveal-open .nav-toggle-bar:last-child {
//   opacity: 1;
//   transform: translate(0, -1px) rotate(-45deg) scale(1.2, 1)
// }


.sticky-container {
  // z-index: 9999;
  width: 100%;
  @include breakpoint(medium) {
    position: fixed;
  }
}

