.slick-slider {
  overflow: hidden;
}
.slick-slide {
  // position: relative;
}
.slick-track {
  // display: flex;
}
.slick-initialized {
  .slick-slide {
    // display: flex;
  }
}
.slick-dots {
  li {
    button {
      background-color: $medium-gray;
      text-indent: 9999px;
      border-radius: 10px;
    }
    &.slick-active {
      button {
        background-color: $primary-color;
      }
    }
  }
}
.carousel-nav {
  // width: 100%;
  // height: 77px;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  button {
    position: absolute;
    display: flex;
    transition: all 0.6s ease;
    &.myslick-prev,
    &.myslick-next {
      img {
        width: 77px;
        height: 82px;
      }
    }
    &.myslick-prev {
      left: 20px;
      border-radius: 0;
      &.disabled {
        left: -77px;
      }
    }
    &.myslick-next {
      right: 20px;
      border-radius: 0;
      &.disabled {
        right: -77px;
      }
    }
    &.disabled {
      box-shadow: none;
      cursor: default;
      img {
        opacity: 0.4;
      }
    }
  }
}

.carousel-item {
  &.active {
    z-index: 99;
  }
}

/*.luminous-carousel {
  margin-bottom: rem-calc(30);
}
.luminous-item {
  // min-width: 250px;
  // @include breakpoint(medium) {
    padding: rem-calc(5 10 0);
  // }
}
.luminous-item-wrapper {
  padding: rem-calc(20 10);
  text-align: center;
  background-color: $white;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 0 0 auto;
  .lead {
    margin-bottom: 0;
    font-size: rem-calc(12);
    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
  img {
    width: auto !important;
    margin-bottom: rem-calc(20);
  }
}*/