
.cookie-disclaimer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  .cookie-disclaimer-content {
    padding: 15px 30px 10px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    color: #fff;
    p {
      margin: 0;
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}